<template>
  <div
  :style="{
    backgroundImage: `url(${require('@/assets/images/page-img/cards_resources_vector.png')})`,
  }"
  >
    <template v-if="showText">
      <h1 v-if="title" class="text-h4 secondary-prosk mb-4">
        {{ title }}
      </h1>
      <h3 class="text-md-h4 text-h5 my-3 secondary-prosk text-center font-weight-bold">{{ $t('focusPassion') }}</h3>
      <h3 class="text-md-h4 text-h5 my-3 text-center">{{ $t('weHelpImprove') }}</h3>
    </template>

    <v-col cols="10" md="8" offset-md="2" offset="1">
      <video v-if="!isYoutube" class="w-100" controls>
        <source :src="videoUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div class="video-container">
        <iframe width="560" height="315" :src="videoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" class="border-radius-medium video-iframe" allowfullscreen></iframe>
      </div>
      <slot></slot>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'VideoBanner',
  props: {
    videoUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    isYoutube: {
      type: Boolean,
      default: false
    },
    showText: {
      type: Boolean,
      default: true
    }
  }
};
</script>
